<template>
  <div
    class="transition-expand-wrapper"
    :class="{ open: isOpened, 'overflow-visible': isOverflowVisible }"
  >
    <div ref="content" class="transition-expand-content">
      <Transition
        name="expand"
        @before-enter="isOpened = true"
        @before-leave="isOpened = false"
      >
        <slot />
      </Transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import { delay } from '@st/utils'

interface Props {
  withOverflow?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  withOverflow: false,
})

const isOpened = ref(false)
const content = ref<HTMLDivElement>()

onMounted(() => {
  isOpened.value = content.value?.children.length !== 0
})

const isOverflowVisible = ref(false)
watchEffect(async () => {
  if (isOpened.value && props.withOverflow) {
    await delay(300)
    isOverflowVisible.value = true
  } else isOverflowVisible.value = false
})
</script>

<style scoped>
.transition-expand-content {
  overflow: hidden;
}

.transition-expand-wrapper {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.2s cubic-bezier(0.28, 0.11, 0.32, 1);

  &.open {
    grid-template-rows: 1fr;
  }

  &.open.overflow-visible {
    .transition-expand-content {
      overflow: visible;
    }
  }
}

.expand-enter-active,
.expand-leave-active {
  transition: none 0.2s;
}
</style>
